<template>
  <div class="preview-box">
    <div class="preview-box-inner">
      <v-icon
        class="no-print print-preview"
        @click="print()"
      >mdi-printer</v-icon>
      <v-icon
        class="no-print close-preview"
        @click="goBack()"
      >mdi-close-circle</v-icon>
      <div class="preview-inner">
        <v-row class="mt-0">
          <v-col class="col-8"></v-col>
          <v-col class="col-4">
            <img width="180px" src="../../assets/logo.png" alt="" />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="col-8 address-corner">
            <p>
              {{ quotation.customer }}<br />
              <span v-if="quotation.customerAddressOne">{{ quotation.customerAddressOne }}, <br /></span>
              <span v-if="quotation.customerAddressTwo">{{ quotation.customerAddressTwo }}, <br /></span>
              <span v-if="quotation.customerAddressThree">{{ quotation.customerAddressThree }}, <br /></span>
              <span v-if="quotation.customerAddressFour">{{ quotation.customerAddressFour }}, <br /></span>
              <span v-if="quotation.customerAddressFive">{{ quotation.customerAddressFive }}, <br /></span>
              <span v-if="quotation.customerPostcode">{{ quotation.customerPostcode }}</span>
            </p>
          </v-col>
          <v-col class="col-4">
            <p>
              Unit X Lodge Lane Industrial Estate<br />
              Tuxford, Newark, Notts NG22 0NL
            </p>
            <p>
              Office: 0800 8488414
              <br />
              Email: info@riggott.co.uk
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="col-8">
            <h2 class="large-heading">Works Estimate</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-8">
            <p class="mb-0">
              <span class="text-orange text-heavy">
                For the attention of:
              </span>
              {{ quotation.contact }}
              <br />
              <span class="text-orange text-heavy">
                Project address:
              </span>
              {{ quotation.site }}
              {{ quotation.postCode }}
            </p>
            <span class="text-orange text-heavy float-left">
              Description of works:&nbsp;
            </span>
            <div class="unspaced-p" v-html="quotation.description"></div>
            <p class="pt-4">
              We are pleased to submit our estimate for the works to be carried out at the above
              site, as detailed on the attached Bill Of Quantities page(s)
            </p>
            <p>
              We at Riggotts pride ourselves on the quality of our service. Our primary objective
              is to get the job done right, first time, and at a competitive price.
            </p>
            <p>
              If you require any further advice or assistance, please do not hesitate to contact
              us by phone, fax, email, or through our website which can be found at
              www.riggott.co.uk
            </p>
            <p class="text-orange text-heavy">
              Thank you for the opportunity of pricing these works for you, and we hope to be
              of service soon
            </p>
            <p>
              Yours sincerely,<br />
              for Riggott &amp; Co Ltd
            </p>
            <p>
              {{ quotation.quotedBy }}<br />
              {{ quotation.quotedByRole }}
            </p>
          </v-col>
          <v-col class="col-4">
            <p>
              <span class="text-orange text-heavy">
                Our reference:
              </span>
              {{ quotation.jobNumber }}
              <br />
              <span class="text-orange text-heavy">
                Your reference:
              </span>
              {{ quotation.customerReference }}
              <br />
              <span class="text-orange text-heavy">
                Date:
              </span>
              {{ quotation.date | shortDate }}
              <br />
              <span class="text-orange text-heavy">
                Dwg reference:
              </span>
              {{ quotation.drawing }}
            </p>
            <p>
              <span class="text-orange text-heavy">Contract Value:</span><br />
              <span
                v-if="quotation.value > quotation.minChargeAmount"
                class="bold-price">
                {{ quotation.value | priceInPounds | currency }}
                <br />
              </span>
              <span
                v-else
                class="bold-price">
                {{ quotation.minChargeAmount | priceInPounds | currency }}
                <br />
              </span>
              <span class="text-heavy">Subject to final site measure</span>
            </p>
            <p class="pt-10">
              <span class="text-heavy">Useful Contacts</span>
            </p>
            <p>
              <span class="text-heavy text-orange">Direct contact number:</span><br />
              {{ quotation.quotedByTel }}<br />
              <span v-if="quotation.quotedByMobile">
                <span v-if="quotation.quotedByMobile.length > 3">
                  {{ quotation.quotedByMobile }}
                </span>
              </span>
            </p>
            <p>
              <span class="text-heavy text-orange">Direct email:</span><br />
              {{ quotation.quotedByEmail }}
            </p>
          </v-col>
        </v-row>
        <v-row class="pt-2">
          <v-col class="col-12 border-top">
            <p class="text-center mb-0">
              Line marking | Surface coating | Car park maintenance
            </p>
            <p class="text-center">
              <img width="50px" src="../../assets/bba.png" alt="" />
              &nbsp;
              <img width="50px" src="../../assets/british_quality_foundation.png" alt="" />
              &nbsp;
              <img width="50px" src="../../assets/safe_constructor_approved.png" alt="" />
              &nbsp;
              <img width="50px" src="../../assets/chas.png" alt="" />
              &nbsp;
              <img width="35px" src="../../assets/black_logo.png" alt="" />
              &nbsp;
              <img width="35px" src="../../assets/constructor_line.png" alt="" />
              &nbsp;
              <img width="50px" src="../../assets/nvq.png" alt="" />
              &nbsp;
              <img width="120px" src="../../assets/2021_ISO9001-ISO14001-ISO45001_White_UKAS_SystemCert_COLOUR.jpg" alt="" />
            </p>
          </v-col>
        </v-row>
      </div>
      <div class="preview-inner">
        <v-row>
          <v-col class="col-8"></v-col>
          <v-col class="col-4">
            <img width="180px" src="../../assets/logo.png" alt="" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-8 address-corner">
            <h2 class="large-heading">
              Works estimate<br />
              Bill of Quantities
            </h2>
          </v-col>
          <v-col class="col-4">
            <p>
              <span class="text-orange text-heavy">Our reference:</span>
              {{ quotation.jobNumber }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-6">
            <span class="text-orange text-heavy">
              Description of work:
            </span>
          </v-col>
          <v-col class="col-1">
            <span class="text-orange text-heavy">
              Item
            </span>
          </v-col>
          <v-col class="col-1">
            <span class="text-orange text-heavy">
              Qty
            </span>
          </v-col>
          <v-col class="col-2">
            <span class="text-orange text-heavy">
              Cost
            </span>
          </v-col>
          <v-col class="col-2 text-right">
            <span class="text-orange text-heavy">
              Total
            </span>
          </v-col>
        </v-row>
        <v-row v-for="(section, index) in quotation.sections" :key="index">
          <v-col class="col-12 text-heavy pt-0 pb-0">{{ section.title }}</v-col>
          <v-col class="col-12 pt-0 pb-0">
            <v-row
              class="mt-0"
              v-for="(line, qlindex) in section.lines"
              :key="qlindex">
              <v-col class="col-6 pt-0">
                <span class="text-heavy">
                  {{ line.description }}
                </span>
              </v-col>
              <v-col class="col-1 pt-0">
                {{ line.unit }}
              </v-col>
              <v-col class="col-1 pt-0">
                {{ line.quantity }}
              </v-col>
              <v-col class="col-2 pt-0">
                {{ line.price | priceInPounds | currency }}
              </v-col>
              <v-col class="col-2 pt-0 text-right">
                {{ (line.price * line.quantity) | priceInPounds | currency }}
              </v-col>
            </v-row>
          </v-col>
          <v-col class="col-10 text-heavy pt-0">
            Section total:
          </v-col>
          <v-col class="col-2 text-heavy text-right pt-0">
            {{ section.total | priceInPounds | currency }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-8">
            <p>
              <span class="text-heavy">Notes:</span>
            </p>
            <div class="unspaced-p" v-html="quotation.notes"></div>
          </v-col>
        </v-row>
        <v-row v-if="quotation.value > quotation.minChargeAmount">
          <v-col class="col-10">
            <span class="text-orange text-heavy">
              Total (Based on details provided)
            </span>
          </v-col>
          <v-col class="col-2 text-right">
            <span class="text-orange text-heavy">
              {{ quotation.value | priceInPounds | currency }}
            </span>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="col-10">
            <span class="text-orange text-heavy">
              Total (Based on minimum charge)
            </span>
          </v-col>
          <v-col class="col-2 text-right">
            <span class="text-orange text-heavy">
              {{ quotation.minChargeAmount | priceInPounds | currency }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-8">
            &nbsp;
          </v-col>
          <v-col class="col-4 text-right">
            Subject to final site measure
          </v-col>
        </v-row>
        <v-row class="border-top">
          <v-col class="col-9 text-small">
            All works subject to final measure unless otherwise agreed as a lump sum<br />
            Additional extra cost for unsociable hours, night and weekends<br />
            Notice required for starting work<br />
            <span
              v-for="(charge, index) in quotation.minimumCharges"
              :key="index">
              Minimum charge for: {{ charge.minChargeDescription }}
            <br /></span>          
            Surface type that materials are to be applied to<br />
            Visits required for completion of the estimated works
          </v-col>
          <v-col class="col-3 text-right text-small">
            &nbsp;<br />
            {{ quotation.extraUnsociableHours }}%<br />
            {{ quotation.notice }}<br />
            <span
              v-for="(charge, index) in quotation.minimumCharges"
              :key="index">
              {{ charge.minChargeValue | priceInPounds | currency }}
            <br /></span>          
            {{ quotation.surfaceType }}<br />
            {{ quotation.days }}
          </v-col>
        </v-row>
        <v-row class="border-top">
          <v-col class="col-9 text-small">
            <span class="text-heavy">Terms</span><br />
            Price estimate is valid for a period of: {{ quotation.validFor }}<br />
            The site visit minimum charge applies if the measured work is below the value shown<br />
            Payment Terms: {{ paymentTerms }}<br />
            Please make contracts and payments out to Riggott & Co. Ltd.<br />
            All estimates are exclusive of VAT (VAT No. 598 6171 83)<br />
            Acceptance of this estimate implies acceptance of our standard T&C as below, full T&C available<br />
            Subject to our terms and conditions below:
          </v-col>
          <v-col class="col-3">
            <span class="text-heavy">Payment</span><br />
            <img src="../../assets/payment_logos.png" alt="" />
          </v-col>
        </v-row>
      </div>
      <div class="preview-inner">
        <v-row>
          <v-col class="col-8">&nbsp;</v-col>
          <v-col class="col-4">
            <img width="180px" src="../../assets/logo.png" alt="" />
          </v-col>
        </v-row>
        <v-row class="bg-orange">
          <v-col class="col-12 address-corner print-no-to-marg">
            <h2 class="terms-heading">Important conditions applicable to this estimate</h2>
            <p class="text-orange text-heavy text-small">
              The following section supports our standard terms and condition for the supply of
              services, and upon acceptance of this estimate, these will form part of the main
              Terms and Conditions for the supply of services.
            </p>
          </v-col>
        </v-row>
        <v-row class="print-no-top-marg">
          <v-col class="col-6">
            <p class="numbered-para" data-number="1.">
              <span class="text-heavy">Traffic management</span> is not included (quotations
              available). Work cancelled, postponed or delayed due to lack of traffic management
              at the Site, or due to staff working conditions. Please see section 7 of our Terms &
              Conditions.
            </p>
            <p class="numbered-para" data-number="2.">
              <span class="text-heavy">Marking materials</span> will not be applied to surfaces in
              conditions outside the manufacturer's specification (ie where the surface is not
              clean or dry, or the temperature is less than 5 degrees C) unless a disclaimer has
              been signed by the Customer. Further limitations are applicable; please see our Terms
              & Conditions. Section 6.
            </p>
            <p class="numbered-para" data-number="3.">
              <span class="text-heavy">A Minimum charge</span> applies to all quotations given.
              This is to cover the costs of mobilisation, labour, plant and materials up to the
              value of the measured work at the given rates and at one Site only. For a full set
              of Minimum Charges, please see Section 5.5 of our  Terms & Conditions.
            </p>
            <p class="numbered-para" data-number="4.">
              <span class="text-heavy">A day rate</span> for the Charges can be provided on request
              based on distance, time on Site (up to ten hours) and the hourly cost of labour and
              plant. Costs and inlcusions are specifically identified under Section 5.6 of our
              Terms & Conditions.
            </p>
            <p class="numbered-para" data-number="5.">
              <span class="text-heavy">Waiting or non-productive time</span> (whether or not caused
              by the Customer, but excluding that resulting from inclement weather conditions) will
              be charged at the hourly cost of labour and plant. 
            </p>
            <p class="numbered-para" data-number="6.">
              <span class="text-heavy">Cancellation</span> The Customer may not cancel any order
              for Services for which a written Acknowledgment of Order has been issued by the
              Company pursuant to Condition 2.5 of our Terms & Conditions. and if the Customer
              cancels, or purports to cancel any such order (or the Contract or any part thereof),
              then the Company may, by notice in writing to the Customer, elect to treat the
              Contract as repudiated. Without prejudice to the provisions of Conditions 2.5 or
              2.6 of our Terms & Conditions., the Company reserves the right to levy a Minimum
              Charge where the Customer cancels an order within 12 hours of the requested or
              intended start time for performance of the Services.
            </p>
            <p class="numbered-para" data-number="7.">
              <span class="text-heavy">Removal of redundant markings</span> The Company shall not
              be responsible for removing any redundant markings unless this is stipulated in the
              Acknowledgment of Order. Removal of redundant markings will be carried out by the
              most appropriate method (as determined by the Company, in its sole discretion) and
              the rate stipulated in the Company's Acknowledgment of Order shall be to remove a
              maximum thickness of materials of 3mm (the rate for anything in excess of 3mm shall
              be subject to further negotiation). Full details of Marking Materials can be found
              under Section 6 of our Terms & Conditions.
            </p>
            <p class="numbered-para" data-number="8.">
              <span class="text-heavy">Setting out marks</span> in non-permanent crayon will
              disappear naturally, and removal of such marks has not been provided for in the
              Company's rates. The Company reserves the right to levy an extra charge if the
              Company agrees to remove any setting-out marks.
            </p>
            <p class="numbered-para" data-number="9.">
              <span class="text-heavy">Force drying</span> is not included in the Company's rates
              unless otherwise stated. The Company reserves the right to levy an extra 20% charge
              if the Company agrees to undertake any force drying, without any guarantee as to the
              performance of the materials
            </p>
            <p class="numbered-para" data-number="10.">
              <span class="text-heavy">Block paving, concrete, very smooth power floated surface
              </span> or surfaces which are less than 3 months old are not considered to be
              suitable surfaces for the application of marking materials and the performance of
              marking materials applied to any of these surfaces cannot be guaranteed. More details
              can be found under Section 6 of our Terms & Conditions.
            </p>
          </v-col>
          <v-col class="col-6">
            <p class="numbered-para" data-number="11.">
              <span class="text-heavy">Colour stability</span> of marking materials cannot be
              guaranteed, as colour stability is dependent on location and environmental
              conditions. Lines will become dirty when used in an indoor environment and will
              require routine washing by the Customer.
            </p>
            <p class="numbered-para" data-number="12.">
              <span class="text-heavy">When re-covering markings</span> the resulting finish will
              depend on the condition and integrity of the existing lines. Where parts of the
              original markings are damaged or missing, a variation in thickness will result.
              Customers should give special consideration as to the required overall thickness when
              lines are re-covered, as trip hazards and drainage problems may result from the use
              of materials of inadequate thickness, for which the Company shall not be liable.
            </p>
            <p class="numbered-para" data-number="13.">
              <span class="text-heavy">Surface requiring tack coat</span> will incur an increase
              in rates of 50% unless otherwise stated.
            </p>
            <p class="numbered-para" data-number="14.">
              <span class="text-heavy">Paint will</span> be applied by airless spray machine unless
              otherwise stated. Customers should be aware that the finished line, whilst adequate
              for most applications, will not have edge lines as crisp as when the lines are
              masked. A hand finished appearance can be achieved at extra cost.
            </p>
            <p class="numbered-para" data-number="15.">
              <span class="text-heavy">The performance of Marking Materials and durability</span>
              of paint and paint based surface treatments cannot be guaranteed, as this is
              dependent on surface type, condition and the wear to which it is subjected.
              Accordingly the Company's responsibility for the Products and Services is therefore
              limited to when any Product failure is due to their failure to provide the Services
              using reasonable care and skill.
            </p>
            <p class="numbered-para" data-number="16.">
              <span class="text-heavy">Out of hours</span> work (including weekend work) will incur
              an extra 20% charge unless otherwise agreed.
            </p>
            <p class="numbered-para" data-number="17.">
              <span class="text-heavy">Coverage rates</span> for application of resins to
              bituminous surfaces are dependent on texture and age. Bitumen bleed will be
              problematic on surfaces less than 28 days old. Rates may vary considerably from those
              quoted if the Company is instructed to lay on new surfaces and a sample will be
              applied to determine the required coverage.
            </p>
            <p class="numbered-para" data-number="18.">
              <span class="text-heavy">Lines that</span> are laid to meet the kerb edge will fall
              short because of the logistics of machine laying, but are extendible by hand to meet
              the kerb edge, on request only and at extra cost.
            </p>
            <p class="numbered-para" data-number="19.">
              <span class="text-heavy">Coloured surfaces</span> will be charged inclusive of masked
              areas where materials have to be naturally wasted.
            </p>
            <p class="numbered-para" data-number="20.">
              <span class="text-heavy">Responsibility for defects</span> will only be acknowledged
              for defects in materials and/or workmanship which arise after completion of works.
            </p>
            <p class="numbered-para" data-number="21.">
              <span class="text-heavy">The client shall be responsible for setting out the works,
              </span> or for overseeing and confirming the correct setting- out of works by the
              Company's personnel. Any errors in setting out shall be the Customer's sole
              responsibility.
            </p>
            <p class="numbered-para" data-number="22.">
              <span class="text-heavy">Rates submitted are for the application of surface marking
              & surface treatment materials to a clean sound substrate.</span> Unless otherwise
              stated, our quotation includes for light hand sweeping. Should there be a requirement
              for additional cleaning or surface repairs / preparation, then this should be carried
              out in advance of our attendance on site to avoid potential additional charges.
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'QuotationsPreview',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      quotation: {
        quotationLines: [],
      },
      paymentTerms: '',
    };
  },
  watch: {
    quotation: {
      handler() {
        document.title = `Riggott estimate no ${this.quotation.jobNumber}`;
      },
    },
  },
  methods: {
    print() {
      window.print();
    },
    goBack() {
      this.$router.go('-1');
    },
    getQuotation() {
      const quotationId = this.$route.params.id;
      axios.get(`/quotations/getSingle/${quotationId}.json?token=${this.token}`)
        .then((response) => {
          this.quotation = response.data;
          if (!response.data.quotationLines) this.quotation.quotationLines = [];
          this.getPaymentTerms();
        });
    },
    getPaymentTerms() {
      const customerId = this.quotation.customerId;
      axios.get(`/customers/getPaymentTerms/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.paymentTerms = response.data.paymentTerms;
        });
    },
  },
  mounted() {
    this.getQuotation();
  },
}
</script>

<style lang="scss" scoped>
.terms-heading {
  fonts-size: 14px;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  .preview-inner {
    margin: 10mm 5mm 10mm 5mm;
  }
  .numbered-para {
    font-size: 10px;
    margin-bottom: 11px;
  }
  .text-small {
    font-size: 14px;
  }
  .print-no-top-marg {
    margin-top: 0;
    padding-top: 0;
  }
}
</style>
